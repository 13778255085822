<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/resume">Resume</router-link> -->
    <!-- <router-link to="/piano">Piano</router-link> -->
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  background-color: black;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
/* Document level adjustments */
html {
  font-size: 20px;
}

/* hot fix to rm wierd white space on right */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

@media (max-width: 900px) {
  html { font-size: 16px; }
}

@media (max-width: 600px) {
  html { font-size: 12px; }
}

</style>
