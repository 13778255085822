import axios from 'axios';

const MAIN_API_URL='https://480be2fmjj.execute-api.us-east-2.amazonaws.com/Prod'

export async function getResumeUrl(){
    const response = await axios.get(`${MAIN_API_URL}/resume`)

    const url = response.data

    return url
}

export async function getSongUrl(songName){
    console.log(songName)
    const response = await axios.get(`${MAIN_API_URL}/song?songName=${songName}`)

    const url = response.data

    return url
}