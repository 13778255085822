<template>
    <div class="skillComponent">
        <div class="skillComponentOuterContainer">
            <div class="skillComponentInnerContainer">
                <img class="skillIcon" :src="require(`@/assets/skill_images/${imgSrc}`)">
                <h5><slot></slot></h5>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'SkillComponent',
    props: ['iconPath'],
    data(){
        return {
            imgSrc: this.iconPath
        }
    }
}
</script>

<style scoped>
.skillComponentOuterContainer {
    height: 100%;
    padding: 1vh;
}

.skillComponentInnerContainer {
    height: 100%;
    /* margin: 1vh; */
    border:1px solid black;
}
.skillIcon {
    height: 5vh;
    margin-top: .25vh;
}
</style>