<template>
  <div class="home">
    <div class="container-flex">
      <div class="row">
        <div class="col">
          <div class="bannerContainer">
            <!-- <img class="bannerImage" src="../assets/ladue.png"> -->
            <h1 class="bannerText1">Brenden Judson</h1>
            <button class="resumeButton" @click="getResume()">Checkout My Resume</button>
            <h3 class="bannerText2OnImage">A full stack engineer working as a Cloud Architect for Trek10 with a focus on machine learning. Outside of my career I am a technologist, hunter, and pianist.</h3>
          </div>
        </div>
      </div>
      <h3 class="bannerText2BelowImage">A full stack engineer working as a Cloud Architect for Trek10 with a focus on machine learning. Outside of my career I am a technologist, hunter, and pianist.</h3>

      <div class="container-flex">
        <div class="row">
          <div class="col-3 certContainer">
            <img class="certBadge" src="../assets/aws-pro-cert.png">
            <br/>
            <img class="certBadge" src="../assets/aws-ml-cert.png">
          </div>
          <div class="col-9" style="background: silver;">
            <h1>Blogs Posts & Case Studies</h1>
            <ul>
              <li><a href="https://www.trek10.com/case-studies/wabash-improves-student-matriculation-with-machine-learning">Wabash Case Study</a></li>
              <li><a href="https://www.trek10.com/case-studies/chatkitty-powers-premier-chat-experience-with-ai-driven-content-moderation-on-amazon-comprehend">Chat Kitty Case Study</a></li>
              <li><a href="https://www.trek10.com/blog/s3-permissions">S3 Permissions Blog Post</a></li>
            </ul>
            <h1>Projects</h1>
            <ul>
              <li>Proxmox Homelab</li>
              <li>RaspberryPi Electronic Skateboard</li>
              <li>MarioKart AI</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col" style="background: black; color: white;">
            <h1>Publications</h1>
            <!-- <li>IITSEC</li> -->
            <p>Compliant Cloud+Campus Hybrid HPC Infrastructure, SC18, HPC Systems Professional Workshop</p>
            <p>Cloud IaaS for Mass Spectrometry and Proteomics, HPDC ScienceCloud 2017</p>
            <p>Cloud Event Programming Paradigms: Applications and Analysis, IEEE 2016 Conference on Cloud Computing</p>
          </div>
        </div>
        <div class="row">
          <div class="col" style="background: silver;">
            <h1>Skills</h1>
            <div class="row">
              <SkillComponent class="col-4" :iconPath="'tensorflow.png'">Data Science & Machine Learning</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'devops.png'">Devops</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'eth.png'">Blockchain</SkillComponent>
            </div>
            <div class="row">
              <SkillComponent class="col-4" :iconPath="'aws.png'">AWS</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'python.png'">Python</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'nodejs.png'">NodeJS</SkillComponent>
            </div>
            <div class="row">
              <SkillComponent class="col-4" :iconPath="'solidity.png'">Solidity & Web3</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'docker.png'">Docker</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'kubernetes.png'">Kubernetes</SkillComponent>
            </div>
             <div class="row">
              <SkillComponent class="col-4" :iconPath="'iot.png'">IoT</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'vue.png'">Vue</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'html_css_js.png'">HTML/CSS/JS</SkillComponent>
            </div>
            <div class="row">
              <SkillComponent class="col-4" :iconPath="'proxmox.png'">Proxmox & Homelabs</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'elite.png'">Archery</SkillComponent>
              <SkillComponent class="col-4" :iconPath="'piano.png'" @click="$router.push('piano')">Piano</SkillComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkillComponent from '@/components/SkillComponent.vue'

import { getResumeUrl } from '@/js/aws_api.js'


export default {
  name: 'Home',
  components: {
    SkillComponent
  },
  methods: {
    async getResume(){
      const url = await getResumeUrl()

      window.location.href = url;
    }
  }
}
</script>

<style scoped>

@media (min-width: 900px) {
  .bannerContainer {
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../assets/ladue.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 80vh;
  }
}


@media (max-width: 900px ) {
  .bannerContainer {
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../assets/ladue.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 55vh;
  }
}

@media (max-width: 600px) {
  .bannerContainer {
    position: relative;
    text-align: center;
    color: white;
    background-image: url('../assets/ladue.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 20vh;
  }
}

  .bannerImage {
    width: 100%;
  }

  .bannerText1 {
    position: absolute;
    font-size: 4rem;
    top: 4rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    -webkit-text-stroke: .1rem black;
    font-family: 'Gill Sans';
  }

  @media (min-width: 600px) {
    .bannerText2BelowImage {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .bannerText2BelowImage {
      font-size: 1rem;
      font-family: 'Gill Sans';
      background: black;
      color: white;
      padding: 1rem;
      margin: 0;
    }
  }

  @media (min-width: 600px) {
    .bannerText2OnImage {
      position: absolute;
      font-size: 1rem;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      font-family: 'Gill Sans';
    }
  }

  @media (max-width: 600px) {
    .bannerText2OnImage {
      display: none;
    }
  }

  @media (min-width: 600px) {
    .resumeButton {
      position: absolute;
      bottom: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;
      color: white;
      padding: 1rem 1.7rem 1rem 1.7rem;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      text-decoration: none;
    }
  }

  @media (max-width: 600px) {
    .resumeButton {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;
      color: white;
      padding: .5rem .85rem .5rem .85rem;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      text-decoration: none;
    }
  }

  .certContainer {
    background-color: gray;
  }

  @media (min-width: 600px) {
    .certBadge {
      height: 8rem;
      margin: .75rem;
    }
  }

  @media (max-width: 600px) {
    .certBadge {
      height: 5rem;
      margin: .75rem;
    }
  }
</style>
